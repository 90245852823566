import '@/Common/utils/registerComponentHooks'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/Common/plugins/axios'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './plugins/vuetify-mask'

import {
  browserName,
  browserVersion,
  isAndroid,
  isChrome,
  isEdge,
  isFirefox,
  isIOS,
  isMobile,
  isOpera,
  isSafari,
  osVersion,
} from 'mobile-device-detect'

import App from './App.vue'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import SettingsService from '@/Settings/services/SettingsService'
import Vue from 'vue'
import VueFocus from './Common/directives/vfocus'
import VueGtm from 'vue-gtm'
import Vuelidate from 'vuelidate'
import dateFilter from '@/Common/filters/date.filter'
import device from 'vue-device-detector'
import firstLetterFilter from '@/Common/filters/firstLetter.filter'
import longDateFilter from './Common/filters/long.date.filter'
import router from '@/Common/router/router'
import shortDateFilter from './Common/filters/short.date.filter'
import store from '@/Common/store/index'
import vuetify from './plugins/vuetify'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'

Vue.config.productionTip = false
const isProduction = process.env.NODE_ENV === 'production'

Vue.use(Vuelidate).use(device)

function changeFavicon({ src, highResSrc = null }) {
  const documentHead = document.querySelector('head')

  const etcIcons = [{ rel: 'apple-touch-icon' }, { rel: 'apple-touch-startup-image' }, { rel: 'shortcut icon' }]

  etcIcons.forEach((i) => {
    const setFavicon = document.createElement('link')
    setFavicon.setAttribute('rel', i.rel)
    setFavicon.setAttribute('href', src)
    documentHead?.appendChild(setFavicon)
  })
}

// Register a global custom directive called `v-focus`
Vue.directive('focus', VueFocus)
SettingsService.FetchSettings().then((settings) => {
  const languageSettings = JSON.parse(settings.languageSettings)
  const themeSettings = JSON.parse(settings.themeSettings)
  const cookieConsentScript = settings.cookieConsentScript
  const cookieConsentStyles = settings.cookieConsentStyles
  SettingsService.ApplyCookieConsent(cookieConsentScript, cookieConsentStyles)
  SettingsService.ApplyTheme(themeSettings)
  document.title = languageSettings.application ? languageSettings.application.title || 'Arrival' : 'Arrival'
  const favicon =
    themeSettings.theme && themeSettings.theme.general && themeSettings.theme.general.favicon
      ? themeSettings.theme.general.favicon
      : '/favicon-32x32.png'
  changeFavicon({ src: favicon })
  store.commit('setDestination', settings.destination)
  store.commit('setFeatures', settings.enabledFeatures)
  store.commit('setExportKey', settings.exportKey)
  const oidcSettings = settings.frontEndSettings.oidcSettings
  const isDevelopment = settings.environment && settings.environment.toUpperCase() === 'DEVELOPMENT'
  const tagIds: string[] = []

  // Do I need to get rid of this?
  oidcSettings.automaticSilentRenew = false

  if (settings.aspenwareGoogleAnalyticsTagId) {
    tagIds.push(settings.aspenwareGoogleAnalyticsTagId)
  }

  if (settings.clientGoogleAnalyticsTagId) {
    tagIds.push(settings.clientGoogleAnalyticsTagId)
  }

  Vue.filter('date', dateFilter)
  Vue.filter('shortDate', shortDateFilter)
  Vue.filter('longDate', longDateFilter)
  Vue.filter('firstLetter', firstLetterFilter)

  try {
    if (tagIds && tagIds.length > 0) {
      Vue.use(VueGtm, {
        id: tagIds, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: isDevelopment, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: ['oidcCallback', 'oidcCallbackError'], // Don't trigger events for specified router names (case insensitive) (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
      })
    }
  } catch (error) {
    console.log('Google Analytics ID Is Invalid')
  }

  const browserInfo = {
    isMobile,
    isAndroid,
    isIOS,
    isChrome,
    isSafari,
    isFirefox,
    isOpera,
    isEdge,
    osVersion: `${osVersion}`,
    browserVersion: `${browserVersion}`,
    browserName: `${browserName}`,
  }
  store.commit('setBrowserInfo', browserInfo)

  const logUser = (user) => {
    console.log('OIDC user is loaded:', user)
  }

  const oidcCallbacks = isDevelopment
    ? {
        userLoaded: (user) => logUser(user),
        userUnloaded: () => console.log('OIDC user is unloaded'),
        accessTokenExpiring: () => console.log('Access token will expire'),
        accessTokenExpired: () => console.log('Access token did expire'),
        silentRenewError: () => console.log('OIDC user is unloaded'),
        userSignedOut: () => console.log('OIDC user is signed out'),
        oidcError: (payload) => console.log(`An error occured at ${payload!.context}:`, payload!.error),
        automaticSilentRenewError: (payload) => console.log('Automatic silent renew failed.', payload!.error),
      }
    : {}

  const oidcStore = vuexOidcCreateStoreModule(oidcSettings, { namespaced: true }, oidcCallbacks)
  store.registerModule('oidcStore', oidcStore)

  if (settings.applicationInsightsKey) {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: settings.applicationInsightsKey,
        enableAutoRouteTracking: true,
        // additional settings
      },
    })
    appInsights.loadAppInsights()
    store.commit('setAppInsights', settings.appInsights)
  } else {
    console.warn('Application Insights Key is not set')
  }

  new Vue({
    vuetify,
    router,
    store,
    inject: {
      theme: {
        default: { isDark: false },
      },
    },
    render: (h) => h(App),

    created() {
      store.commit('settings/addSettings', settings)
    },
  }).$mount('#app')
})
