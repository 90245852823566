<template>
  <div id="rental-profile-contact-task" v-if="!isHidden">
    <v-card v-if="isReadOnly">
      <v-card-title> {{ contactTask.contact.firstName }} {{ contactTask.contact.lastName }} </v-card-title>
      <v-card-text v-if="!hasProfile" class="readonly-body incomplete-body">
        <div class="readonly-item readonly-item-text">Not Completed</div>
        <div class="button-wrapper">
          <v-btn @click="edit" color="primary" rounded>Update</v-btn>
        </div>
      </v-card-text>
      <v-card-text class="readonly-body" v-else>
        <div class="readonly-item">
          <span class="readonly-item-title">Ability</span>
          <div class="readonly-item-text">
            <div class="icon">
              <icon type="ski" color="#517994" />
            </div>
            <span>{{ profileAbility }}</span>
          </div>
        </div>
        <div class="readonly-item">
          <span class="readonly-item-title">Height</span>
          <div class="readonly-item-text">
            <div class="icon">
              <icon type="ruler" color="#517994" />
            </div>
            <span>{{ profileHeight }}</span>
          </div>
        </div>
        <div class="readonly-item">
          <span class="readonly-item-title">Weight</span>
          <div class="readonly-item-text">
            <div class="icon">
              <icon type="scale" color="#517994" />
            </div>
            <span>{{ profileWeight }}</span>
          </div>
        </div>
        <div class="readonly-item" v-if="profile.shoeSize && enableShoeSize">
          <span class="readonly-item-title">Shoe Size</span>
          <div class="readonly-item-text">
            <div class="icon">
              <icon type="shoe" color="#517994" />
            </div>
            <span>{{ profile.shoeSize }}</span>
          </div>
        </div>
        <div v-if="enableStance" class="readonly-item">
          <span class="readonly-item-title">Stance</span>
          <div class="readonly-item-text">
            <div class="icon">
              <icon type="switch" color="#517994" />
            </div>
            <span v-text="goofy ? 'Goofy foot' : 'Regular'"></span>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn button-type="secondary" @click="edit" v-if="hasProfile" rounded outlined>Update</v-btn>
        <v-btn button-type="primary" @click="edit" v-else rounded>Create</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else class="rental-profile-form" rounded>
      <!-- Contact Information -->
      <v-card-title id="contact-information">
        {{ contactTask.contact.firstName }} {{ contactTask.contact.lastName }}
      </v-card-title>

      <v-card-text>
        <v-form ref="rentalprofile">
          <!-- Ability -->
          <v-select
            name="ability"
            v-model="editProfile.selectedAbility"
            :items="abilityValues"
            placeholder="Select an ability"
            label="Ability"
            item-value="listKey"
            item-text="listDescription"
            :error-messages="getValidationErrors('selectedAbility')"
            outlined
            required
            hide-details="auto"
          />
          <v-card-text>
            <div class="link">
              <a :href="skierAbilityUrl" target="_blank">{{ skierAbilityText }}</a>
            </div>
          </v-card-text>

          <!-- Height -->
          <v-select
            name="height"
            v-model="editProfile.selectedHeight"
            :items="heightValues"
            placeholder="Select height"
            label="Height"
            item-value="listKey"
            item-text="listDescription"
            :error-messages="getValidationErrors('selectedHeight')"
            outlined
            required
          />

          <!-- Weight -->
          <v-select
            name="weight"
            v-model="editProfile.selectedWeight"
            :items="weightValues"
            placeholder="Select weight"
            label="Weight"
            item-value="listKey"
            item-text="listDescription"
            :error-messages="getValidationErrors('selectedWeight')"
            outlined
            required
          />

          <!-- Convert units from metric to imperial -->
          <div class="units-conversion">
            <label>Units are <span v-if="isMetric">metric</span><span v-else>imperial</span></label>
            <v-switch v-model="isMetric" label="Metric" />
          </div>

          <!-- Shoe Size -->
          <v-text-field
            v-if="enableShoeSize"
            type="text"
            label="Shoe Size"
            placeholder="Shoe Size"
            v-model="editProfile.shoeSize"
            outlined
          />

          <!-- Stance -->
          <div v-if="enableStance">
            <label>Stance</label>
            <v-switch type="text" placeholder="Y/N" label="Goofy Foot" v-model="goofy" inset outlined />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn @click="cancel" v-if="showSkipButton || showCancelButton" rounded outlined>{{ cancelLabel }}</v-btn>
        <v-spacer />
        <v-btn
          @click="completeRental"
          color="secondary"
          :disabled="isUpdating"
          :show-loader="isUpdating"
          :class="{ 'only-button': !showCancelButton && !showSkipButton }"
          :block="!showSkipButton && !showCancelButton"
          rounded
          >{{ updateLabel }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { Component, Prop, Vue, Emit, Model, PropSync } from 'vue-property-decorator'
import ContactTaskBase from './ContactTaskBase'
import ContactTask from '../interfaces/ContactTask'
import TaskService from '../services/TaskService'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import RentalProfileInterface from '../interfaces/RentalProfile'
import AxSelect from '@/Common/components/AxSelect.vue'
import Icon from '@/Common/components/Icon.vue'
import RentalProfile from '../models/RentalProfile'
import { required } from 'vuelidate/lib/validators'

@Component({
  components: {
    AxSelect,
    Icon,
  },
})
class RentalProfileForm extends ContactTaskBase {
  @Prop({ required: true, default: false }) isReadOnly: boolean
  @Prop({ required: true, default: false }) isHidden: boolean
  @Prop({ required: true, default: false }) isUpdating: boolean
  @Prop({ required: true, default: false }) enableShoeSize: boolean
  @Prop({ required: true, default: false }) enableStance: boolean
  @Prop({ required: true, default: null }) profile: RentalProfile | null

  @Getter('settings/languageSettings') language

  protected isMetric: boolean = false
  protected editProfile: RentalProfile | null = null
  protected goofy: boolean = false

  created() {
    this.editProfile = new RentalProfile(this.profile)
    this.goofy = this.editProfile.selectedGoofy === 'Y'
  }

  validations() {
    return {
      editProfile: {
        selectedAbility: { required },
        selectedAge: { required },
        selectedHeight: { required },
        selectedWeight: { required },
      },
    }
  }

  private getValidationErrors(profileKey) {
    const errors: any[] = []
    if (!this.$v!.editProfile![profileKey]!.$dirty) {
      return errors
    }
    if (!this.$v!.editProfile![profileKey]!.required) {
      errors.push('This is required.')
    }
    return errors
  }

  private isRentalProfileComplete(profile) {
    return false
  }

  get hasProfile() {
    return this.profile!.selectedAbility
  }

  get cancelLabel() {
    return this.showSkipButton ? 'Skip' : 'Cancel'
  }

  get updateLabel() {
    return !this.showCancelButton && !this.showSkipButton ? 'Continue' : 'Update'
  }

  get showSkipButton() {
    if (!this.isRentalProfileComplete(this.editProfile) && this.contactTask.isOptional) {
      return true
    }
    return false
  }

  get showCancelButton() {
    return this.hasProfile
  }

  get profileAbility() {
    const value = this.profile!.abilityRefs.find((r) => r.listKey.toString() === this.profile!.selectedAbility)
    if (value) {
      return value.listDescription
    }

    return null
  }

  get profileHeight() {
    const value = this.profile!.heightRefs.find((r) => r.listKey.toString() === this.profile!.selectedHeight)
    if (value) {
      return value.listDescription
    }

    return null
  }

  get profileWeight() {
    const value = this.profile!.weightRefs.find((r) => r.listKey.toString() === this.profile!.selectedWeight)
    if (value) {
      return value.listDescription
    }

    return null
  }

  get profileAge() {
    const value = this.profile!.ageRefs.find((r) => r.listKey.toString() === this.profile!.selectedAge)
    if (value) {
      return value.listDescription
    }

    return null
  }

  get abilityValues() {
    return this.profile
      ? this.profile.abilityRefs
          .filter((r) => r.dinLookupValue > -1)
          .map((v) => {
            return {
              dinLookupValue: v.dinLookupValue,
              listDescription: v.listDescription,
              listKey: v.listKey.toString(),
            }
          })
      : null
  }

  get heightValues() {
    return this.profile
      ? this.profile.heightRefs
          .filter((r) => r.dinLookupValue > -1)
          .map((v) => {
            const heightV = v.listDescription
            const heightEngV = `${heightV
              .split('/')[0]
              .trim()
              .replace('.', `'`)
              .replace(' - ', '" - ')
              .replace('.', `'`)}"`
            const heightMetV = heightV.split('/')[1].trim()
            return {
              dinLookupValue: v.dinLookupValue,
              listDescription: this.isMetric ? heightMetV : heightEngV,
              listKey: v.listKey.toString(),
            }
          })
      : null
  }

  get weightValues() {
    return this.profile
      ? this.profile.weightRefs
          .filter((r) => r.dinLookupValue > -1)
          .map((v) => {
            const weightV = v.listDescription
            const weightEngV = weightV.split('/')[0].trim()
            const weightMetV = weightV.split('/')[1].trim()
            return {
              dinLookupValue: v.dinLookupValue,
              listDescription: this.isMetric ? weightMetV : weightEngV,
              listKey: v.listKey.toString(),
            }
          })
      : null
  }

  get skierAbilityUrl() {
    if (this.language && this.language.rentalProfile) {
      return this.language.rentalProfile.skierAbilityUrl
    }
  }

  get skierAbilityText() {
    if (this.language && this.language.rentalProfile) {
      return this.language.rentalProfile.skierAbilityText
    }
  }

  async completeRental() {
    this.$v!.$touch()

    if (!this.$v!.$error) {
      if (this.editProfile) {
        this.editProfile.selectedGoofy = this.goofy ? 'Y' : 'N'
      }

      // Update the related object with the information on the form
      if (this.contactTask) {
        const newRelatedObject = {
          ...this.contactTask.relatedObject,
          ...this.editProfile,
        }
        this.contactTask.relatedObject = newRelatedObject
        this.$emit('onCompleted', this.contactTask)
      }
    }
  }

  @Emit('onSkipped')
  async skip() {
    return this.contactTask
  }

  @Emit('onEdit')
  async edit() {
    return this.contactTask
  }

  @Emit('onCancelEdit')
  async cancel() {
    this.editProfile = new RentalProfile(this.profile)
    this.contactTask.isComplete = true
    return this.contactTask
  }
}

export default RentalProfileForm
</script>
<style lang="scss" scoped>
#rental-profile-contact-task {
  margin: 1rem 0;
  background: white;
}

.v-btn {
  min-width: 100px;
}

label {
  font-size: 0.875rem;

  display: block;
  margin-bottom: 0.5rem;

  color: rgba(0, 0, 0, 0.8);
}

input,
select {
  font-size: 0.875rem;

  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;

  border: 1px solid #6a6a6a;
  border-radius: 3px;
}

.ax--btn {
  &.only-button {
    justify-content: center;
    width: 100%;
  }
}

.readonly-item > span {
  display: block;
}

.readonly-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  background: linear-gradient(rgba(196, 196, 196, 0.1) 0%, rgba(196, 196, 196, 0.15) 45.6%);

  .contact-name {
    font-size: 1.125rem;
  }
}

.readonly-body {
  display: grid;
  width: 100%;
  padding: 1rem 0.8rem;

  box-shadow: 0 -1px 0 #e8e8ec;

  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 12px;

  @include breakpoint('tablet') {
    grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
  }

  &.incomplete-body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button-wrapper {
      position: static;
      margin: 0;
    }

    .readonly-item-text {
      font-size: 1.2rem;

      margin: 0;

      color: crimson;
    }
  }

  .button-wrapper {
    position: static;
    margin: 1rem 0 0 0;
  }

  .ax--btn {
    font-size: 0.75rem;
    line-height: 32px;
    text-transform: uppercase;

    height: 32px;
    padding: 0 1.25em;
    border-radius: 50px;

    &:hover {
      background: rgba(81, 121, 148, 0.1);
    }
  }
}

.readonly-item {
  margin: 0 5px 15px 0;

  &-title {
    font-size: 0.75rem;

    margin-bottom: 0.5em;

    opacity: 0.65;
  }

  &-text {
    font-size: 0.875rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon {
      flex-basis: 26px;
      width: 20px;
      height: 20px;
    }

    span {
      flex: 1;
    }
  }
}

.units-conversion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem 0 0.5rem;
  position: relative;
  top: 0px;
  margin-bottom: 1rem;
  border-radius: 4px;
  font-size: 0.75rem;

  background: #f2f2f2;
}

.switch-metric {
  font-size: 0.75em;
  cursor: pointer;
  text-decoration: underline;
  text-transform: uppercase;

  color: #3694d0;
}

.ax--select + label {
  margin-top: 0.5rem;
}
</style>
