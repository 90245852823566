<template>
  <div id="onerisk-waiver-container" class="waiver">
    <div class="one-risk__window">
        <iframe id="one-risk__iframe" :src="oneRiskUrl" title="Sign Waiver"></iframe>
    </div>
  </div>
</template>

<script lang="ts">
  import { Action, Getter, Mutation } from 'vuex-class'
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import ContactTask from '../interfaces/ContactTask'
  import SigningData from '../interfaces/SigningData'
  import Icon from '@/Common/components/Icon.vue'
  import CryptoJS from 'crypto-js'

@Component({
  components: {
  },
})
class OneRiskWaiver extends Vue {
  @Prop({ required: true, default: null }) contactTasks: ContactTask[] | null
  @Prop({ required: false, default: null }) signingWaiver: boolean
  @Prop({ required: true, default: null }) oneRiskData: any


  @Getter('oidcStore/oidcUser') user: any
  @Getter('settings/oneRiskSettings') oneRiskSettings
  @Mutation('setPageLoading') setPageLoading

  // Data elements
  public showPad: boolean = false
  public needToScroll: boolean = true
  public newWaiver: boolean = false
  public canvas: any = null
  public signaturePad: any = null
  public signerName: string | null = null
  public agree: boolean = false
  public waiverHtmlDoc: any = null

  // Computed Data Elements

  get oneRiskUrl() {
    if (this.encodedData) {
      const d = new Date();
      const url = `${this.oneRiskSettings.oneRiskUrl}?g=${this.encodedData}&random=${d.getTime()}${Math.floor(Math.random() * 1000000)}`
      return url
    } else {
      return null
    }
  }

  get encodedData() {
    if (this.oneRiskData) {
      // Create an encoded header for the JWT (JSON Wen Token)
      // NOTE: the replaces at the end are to eliminate the padding of "=" chars at the end to make it base64URL encoding
      const header = { alg: 'HS256', typ: 'JWT' }
      const encodedHeader = btoa(JSON.stringify(header))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/\=+$/, '');

      // Encode the data passed to this component as the JWT payload
      // NOTE: the replaces at the end are to eliminate the padding of "=" chars at the end to make it base64URL encoding
      const encodedPayload = btoa(JSON.stringify(this.oneRiskData))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/\=+$/, '');

      // Create a signature for the JWT
      const jwtSecret = this.oneRiskSettings.oneRiskSecret
      const signature = this.createHmacSha256Signature(encodedHeader, encodedPayload, jwtSecret);

      // Concatentate the header, payload and signature into a JWT string
      const jwt = `${encodedHeader}.${encodedPayload}.${signature}`
      return jwt
    } else {
      return null
    }
  }

  createHmacSha256Signature(header: string, payload: string, secret: string): string {
    const data = `${header}.${payload}`;
    const signature = CryptoJS.HmacSHA256(data, secret).toString(CryptoJS.enc.Base64)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/\=+$/, '');
    return signature;
  }

  private allContactNames(conjunction: string) {
    let names: string = ''
    let index = 0

    if (this.contactTasks) {
      this.contactTasks.forEach((ct) => {
        if (this.contactTasks) {
          if (index == this.contactTasks.length - 1 && this.contactTasks.length > 1) {
            names += ` ${conjunction} `
          } else if (index > 0) {
            names += ', '
          }
        }
        names += `${ct.contact.firstName} ${ct.contact.lastName}`
        index++
      })
    }

    return names
  }

  get currentTask() {
    if (this.contactTasks && this.contactTasks.length > 0) {
      return this.contactTasks[0].task
    }
  }

  // Life Cycle
  public mounted() {
    window.addEventListener('message', this.receiveMessage, false)
    this.setPageLoading(true)
  }

  // Methods
 

  public saveSignature() {
    // if (this.enableWetSignature && this.signaturePad.isEmpty()) {
    //   alert('Please Sign.')
    //   return
    // }
  }

  closeWindow() {
    this.$emit('closeWindow')
  }

  receiveMessage(event) {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
    if (!this.oneRiskSettings.oneRiskUrl.startsWith(event.origin)) {
      return
    }

    if (event.data) {
      try {
        const oneRiskResponseData = JSON.parse(event.data)

        switch (oneRiskResponseData.messageType) {
          case 'waiverCompleted':
            const signingData: SigningData = {
              signature: '',
              agree: true,
              signerName: this.signerName,
              waiverHTML: '',
            }
            this.$emit('save-signature', signingData)
            this.closeWindow()
            break
          case 'waiversLoaded':
            this.setPageLoading(false)
            break
          case 'allWaiversCompleted':
            this.$emit('skip-waiver')
            break
          case 'ERROR':
            alert(`ERROR CALLING 1RISK INTERFACE: ${oneRiskResponseData.status} ${oneRiskResponseData.body}`)
            console.log(`ERROR CALLING 1RISK INTERFACE: ${oneRiskResponseData.status} ${oneRiskResponseData.body}`)
            this.closeWindow()

            break
        }

      } catch (ex) {
        console.error(`ERROR: BAD REPONSE FROM 1RISK -${ex}`)
      }
    }
  }
}

export default OneRiskWaiver
</script>

<style lang="scss">
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(255, 255, 255, 0.45) !important;
}
</style>
<style lang="scss" scoped>
h1 {
  margin-bottom: 0;
}

.waiver {
  background-color: white;
  z-index: 200;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 100%;
}


  .one-risk__window {
    margin: 0px 15px 0 15px;
    padding: 1rem;
    border: 2px solid #ccc;
    font-weight: 700;
    color: black;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    height: calc(100vh - 100px);
    min-height: 330px;

    p {
      color: black !important;
    }
  }
  #one-risk__iframe {
    width: 100%;
    height: 100%;
  }

</style>
